import React from 'react'
import { navigate } from "@reach/router" 
import { Helmet } from 'react-helmet'

const landingPage = () => {
  if(typeof window !== 'undefined') {
    let currentLocale = localStorage.getItem('currentLocale') || 'en'
    localStorage.setItem('currentLocale', currentLocale)
    navigate(`/${currentLocale}/`)
    location.reload()
  }

  return ( <Helmet>
    <meta name="facebook-domain-verification" content="yinnt7vyykpxeb99aehgzla82z2bdf" />
  </Helmet> )
}

export default landingPage